import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Blogs.css";

const Blogs = ({ recentBlogs, oldBlog }) => {
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleSubmit = () => {
    setSearch("");
  };
  return (
    <section className="primary-light-bg">
      <div className="container">
        {/* <div className="blog-searching row">
        <div className="col-md-11 p-0">
          <input
            onChange={(e) => handleSearch(e)}
            type="text"
            name=""
            id=""
            value={search}
            placeholder="Search"
          />
        </div>
        <div className="col-md-1 p-0">
          <button onClick={handleSubmit}>Search</button>
        </div>
      </div> */}
        <div>
          {recentBlogs?.map((data) => (
            <>
              <div className="row text-white blogs-margin-top blogs-div d-flex justify-content-between">
                <div className="col-lg-3 col-md-4 col-sm-12 d-flex mobile-blogs-img-padding ">
                  <Link
                    to={`/blog/${data.blogTitle.replace(/\s+/g, "_")}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="blog-card-img-overflow">
                      <img
                        className="blog-card-img"
                        src={data.coverImg}
                        alt=""
                      />
                    </div>
                    <div className="mobile-blogs-img">
                      <img className="" src={data.coverImg} alt="" />
                    </div>
                  </Link>
                </div>
                <div className="col-lg-9 col-md-8 p-0 col-sm-12 d-flex align-items-center main-blog-card-text">
                  <div className="blog-card-text">
                    <Link
                      to={data.blogTitle.replace(/\s+/g, "_")}
                      style={{ textDecoration: "none" }}
                    >
                      <h4 className="blog-title-text black-gray">
                        {data.blogTitle}
                      </h4>{" "}
                      <div className="read-more-overflow black-gray">
                        <p className="read-more-text">
                          {data.blogContent[0].description}
                        </p>
                      </div>
                      <p className="blog-read-more">Read more...</p>
                    </Link>

                    <div className="d-flex justify-content-between mt-4">
                      <div className="date-and-tag-flex">
                        <div className="date-div">
                          <p style={{ color: "gray" }} className="date">
                            {data.date}
                          </p>{" "}
                          <strong
                            style={{ color: "gray" }}
                            className="date-dot"
                          >
                            .
                          </strong>{" "}
                          <p
                            style={{ color: "gray" }}
                            className="date read-time"
                          >
                            10 min read
                          </p>
                          <strong className="blog-bottom-dot">.</strong>{" "}
                        </div>
                        <div className="blog-buttons">
                          {data.tag.slice(0, 6).map((data, index) => (
                            <Link to={`/blogs/tag/${data.tags}`}>
                              <button className={`tag-btn-${index + 1}`}>
                                <small>{data.tags}</small>
                              </button>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
          {oldBlog?.map((data) => (
            <>
              <div className="row text-white blogs-margin-top blogs-div d-flex justify-content-between">
                <div className="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center mobile-blogs-img-padding">
                  <Link to={data.link} style={{ textDecoration: "none" }}>
                    <div className="blog-card-img-overflow">
                      <img className="blog-card-img" src={data.img} alt="" />
                    </div>
                    <div className="mobile-blogs-img">
                      <img className="" src={data.img} alt="" />
                    </div>
                  </Link>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-12 p-0 d-flex align-items-center main-blog-card-text">
                  <div className="blog-card-text">
                    <Link to={data.link} style={{ textDecoration: "none" }}>
                      <h4 className="blog-title-text black-gray">
                        {data.title}
                      </h4>{" "}
                      <p className="read-more-text black-gray">
                        {data.blogReadMore}
                      </p>
                      <p className="blog-read-more">Read more...</p>
                    </Link>

                    <div className="d-flex justify-content-between mt-4">
                      <div className="date-and-tag-flex">
                        <div className="date-div">
                          <p style={{ color: "gray" }} className="date">
                            {data.date}
                          </p>{" "}
                          <strong
                            style={{ color: "gray" }}
                            className="date-dot"
                          >
                            .
                          </strong>{" "}
                          <p
                            style={{ color: "gray" }}
                            className="date read-time"
                          >
                            10 min read
                          </p>
                          <strong className="blog-bottom-dot">.</strong>{" "}
                        </div>
                        <div className="blog-buttons">
                          {data.tag && (
                            <div>
                              {data.tag.map((data, index) => (
                                <Link to={`/blogs/tag/${data.tags}`}>
                                  {data.tags && (
                                    <button className={`tag-btn-${index + 1}`}>
                                      <small>{data.tags}</small>
                                    </button>
                                  )}
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
