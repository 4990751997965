import React, { useEffect } from "react";
import "./index.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Col, Container, Row } from "react-bootstrap";

const Index = () => {
  useEffect(() => {
    document.title = "T&C | BrainAlive";
  }, []);
  return (
    <>
      <Navbar />
      <Container
        fluid
        className="privacy-margin-top px-0"
        style={{ textAlign: "justify" }}
      >
        <Container className="pb-4">
          <Row className=" heading ">
            <Col lg={12}>
              <div class="content-center">
                <h5 className="fw-bold"> Brainalive’s Terms of Services</h5>
                <br />
                <h6>EFFECTIVE: November 13, 2021</h6>
                <br />
                <p>
                  IMPORTANT, READ CAREFULLY: YOUR USE OF AND ACCESS TO THE
                  WEBSITE AND BRAINALIVE’S FOCII APP AND ASSOCIATED SOFTWARE
                  (COLLECTIVELY, THE “FOCII APP”) OF BRAINALIVE RESEARCH PVT LTD
                  IS CONDITIONED UPON YOUR COMPLIANCE WITH AND ACCEPTANCE OF
                  THESE TERMS, WHICH INCLUDE YOUR AGREEMENT TO ARBITRATE CLAIMS.
                  PLEASE REVIEW THOROUGHLY BEFORE ACCEPTING. BY
                  CLICKING/CHECKING THE “I AGREE” BUTTON/BOX, ACCESSING THE
                  BRAINALIVE FOCII APP, OR BY UTILIZING THE BRAINALIVE’S
                  PRODUCTS AND ITS ASSOCIATED SERVICES YOU AGREE TO BE BOUND BY
                  THESE TERMS OF SERVICES AND ALL EXHIBITS, ORDER FORMS, AND
                  INCORPORATED POLICIES (THE “AGREEMENT” OR “TOS”). THE
                  BRAINALIVE PRODUCTS AND ASSOCIATED SERVICES ARE NOT AVAILABLE
                  TO PERSONS WHO ARE NOT LEGALLY ELIGIBLE TO BE BOUND BY THESE
                  TERMS OF SERVICES.
                </p>
                <br />
                <br />
                <p>
                  BrainAlive will provide the FOCII App, and you may access and
                  use the FOCII App, in accordance with this Agreement.
                  BrainAlive may provide any of the BrainAlive products and its
                  associated services hereunder through any of its Affiliates.
                  If You order BrainAlive’s products and its associated
                  BrainAlive products and its associated services through an
                  online registration page or an order form (each an “Order
                  Form”), the Order Form may contain additional terms and
                  conditions and information regarding the Products you are
                  ordering. Unless otherwise expressly set forth in any such
                  additional terms and conditions applicable to the specific
                  Product which You choose to use, those additional terms are
                  hereby incorporated into this Agreement in relation to Your
                  use of that BrainAlive Products and its associated Services.
                </p>
                <br />
                <br />
                <p>
                  <b>System Requirements: </b> Use of the FOCII App requires one
                  or more compatible devices, Internet access, and may require
                  obtaining updates or upgrades from time to time. Because the
                  use of the FOCII App involves software, and Internet access,
                  your ability to access and use the app may be affected by the
                  performance of these factors. High-speed Internet access is
                  recommended. You acknowledge and agree that such system
                  requirements, which may be changed from time to time, are Your
                  responsibility.
                </p>
                <br />
                <br />
                <p>
                  <b>1. DEFINITIONS: </b> The following definitions will apply
                  in this Agreement, and any reference to the singular includes
                  a reference to the plural and vice versa. To know more about
                  BrainAlive’s FOCII, you can mail us directly at
                  info@braina.live or contact us at
                  https://brainalive.ai/contact.php. “Affiliate” means, with
                  respect to a Party, any entity that directly or indirectly
                  controls, is controlled by, or is under common control with
                  that Party. For purposes of this Agreement, “control” means an
                  economic or voting interest of at least fifty percent (50%)
                  or, in the absence of such economic or voting interest, the
                  power to direct or cause the direction of the management and
                  set the policies of such entity.“End User” means a Host or
                  Participant who uses the FOCII App.
                </p>
                <br />
                <br />
                <p>
                  <b>2. FOCII Desktop App: </b>
                  <br />
                  <strong>1. Beta services.</strong> FOCII may, from time to
                  time, offer access to BrainAlive products and its associated
                  services that are classified as Beta version. Access to and
                  use of Beta versions may be subject to additional agreements.
                  BrainAlive makes no representations that a Beta version will
                  ever be made generally available and reserves the right to
                  discontinue or modify a Beta version at any time without
                  notice. Beta versions are provided as if, may contain bugs,
                  errors, or other defects, and your use of a Beta version is at
                  your sole risk.
                </p>
                <br />
                <br />
                <p>
                  <b>
                    3. USE OF FOCII APP AND YOUR RESPONSIBILITIES (as the
                    Instructor/lecturer/webinar/meeting/host):{" "}
                  </b>
                  You may only use the app pursuant to the terms of this
                  Agreement. You are solely responsible for Your and Your End
                  Users’ use of the FOCII App and shall abide by, and ensure
                  compliance with, all Laws in connection with Your and each End
                  User’s use of the app including but not limited to laws
                  related to recording, intellectual property, privacy and
                  export control. Use of the FOCII App is void where prohibited.
                </p>
                <p>
                  <b>1. Registration Information: </b> You may be required to
                  provide information about Yourself in order to register for
                  and/or use the FOCII app. You agree that any such information
                  shall be accurate. You may also be asked to choose a username
                  and password. You are entirely responsible for maintaining the
                  security of your user name and password and agree not to
                  disclose such to any third party.
                </p>
                <p>
                  <b>2. Your Content: </b> You agree that you are solely
                  responsible for the content (“Content”) sent or transmitted by
                  you or displayed or uploaded by you in using the FOCII App and
                  for compliance with all Laws pertaining to the Content,
                  including, but not limited to, laws requiring you to obtain
                  the consent of a third party to use the Content and to provide
                  appropriate notices of third party rights. You represent and
                  warrant that You have the right to upload the Content to FOCII
                  App and that such use does not violate or infringe on any
                  rights of any third party. Under no circumstances will
                  BrainAlive be liable in any way for any (a) Content that is
                  transmitted or viewed while using the App, (b) errors or
                  omissions in the Content, or (c) any loss or damage of any
                  kind incurred as a result of the use of, access to, or denial
                  of access to Content. Although FOCII is not responsible for
                  any Content, FOCII may delete any Content, at any time without
                  notice to You, if FOCII becomes aware that it violates any
                  provision of this Agreement or any law. You retain copyright
                  and any other rights You already hold in Content which You
                  submit, post, or display on or through, the FOCII App.
                </p>
                <p>
                  <b>3 Recordings: </b> You are responsible for compliance with
                  all recording laws. The host can choose to record Live Class
                  meetings and Webinars. By using the FOCII App, you are giving
                  BrainAlive consent to store recordings for any or all meetings
                  or webinars that you join, if such recordings are stored in
                  our systems. You will receive a notification (visual or
                  otherwise) when recording is enabled. If you do not consent to
                  be recorded, you can choose to leave the meeting or webinar.
                </p>
                <p>
                  <b>4 Prohibited Use: </b> You agree that You will not use, and
                  will not permit any End User to use, the FOCII app to: (i)
                  modify, disassemble, decompile, prepare derivative works of,
                  reverse engineer or otherwise attempt to gain access to the
                  source code of the product and its BrainAlive products and its
                  associated services; (ii) knowingly or negligently use the
                  FOCII App in a way that abuses, interferes with, or disrupts
                  BrainAlive’s networks, Your accounts; (iii) engage in activity
                  that is illegal, fraudulent, false, or misleading, (iv)
                  transmit through the FOCII App any material that may infringe
                  the intellectual property or other rights of third parties;
                  (v) build or benchmark a competitive product or BrainAlive
                  products and its associated services, or copy any features,
                  functions or graphics of the FOCII App; or (vi) use the FOCII
                  App to communicate any message or material that is harassing,
                  libelous, threatening, obscene, indecent, would violate the
                  intellectual property rights of any party or is otherwise
                  unlawful, that would give rise to civil liability, or that
                  constitutes or encourages conduct that could constitute a
                  criminal offense, under any applicable law or regulation;
                  (vii) upload or transmit any software, Content or code that
                  does or is intended to harm, disable, destroy or adversely
                  affect performance of the App in any way or which does or is
                  intended to harm or extract information or data from other
                  hardware, software or networks of BrainAlive or other users of
                  the app; (viii) engage in any activity or use the FOCII App in
                  any manner that could damage, disable, overburden, impair or
                  otherwise interfere with or disrupt the FOCII App features, or
                  any servers or networks connected to the FOCII App or
                  BrainAlive’s security systems. (ix) use the BrainAlive FOCII
                  App in violation of any BrainAlive policy or in a manner that
                  violates applicable law, including but not limited to
                  anti-spam, export control, privacy, and anti-terrorism laws
                  and regulations and laws requiring the consent of subjects of
                  audio and video recordings, and You agree that You are solely
                  responsible for compliance with all such laws and regulations.
                </p>
                <p>
                  <b>5 Limitations on Use: </b> You may not reproduce, resell,
                  or distribute the FOCII App or any reports or data generated
                  by the FOCII App for any purpose unless You have been
                  specifically permitted to do so under a separate agreement
                  with BrainAlive. You may not offer or enable any third parties
                  to use the FOCII App purchased by You, display on any website
                  or otherwise publish the FOCII App or any Content obtained
                  from a FOCII App (other than Content created by You) or
                  otherwise generate income from the FOCII App or use the FOCII
                  App for the development, production or marketing of a
                  BrainAlive products and its associated services or product
                  substantially similar to the FOCII App.
                </p>
                <br />
                <br />
                <p>
                  <b>4. RESPONSIBILITY FOR END USERS: </b> You are responsible
                  for the activities of all End Users who access or use the
                  FOCII App through your account and you agree to ensure that
                  any such End User will comply with the terms of this Agreement
                  and any BrainAlive policies. BrainAlive assumes no
                  responsibility or liability for violations. If You become
                  aware of any violation of this Agreement in connection with
                  the use of the FOCII App by any person, please contact
                  BrainAlive at info@braina.live. BrainAlive may investigate any
                  complaints and violations that come to its attention and may
                  take any (or no) action that it believes is appropriate,
                  including, but not limited to issuing warnings, removing the
                  content, or terminating accounts and/or User profiles. Under
                  no circumstances will BrainAlive be liable in any way for any
                  data or other content viewed while using the FOCII App,
                  including, but not limited to, any errors or omissions in any
                  such data or content, or any loss or damage of any kind
                  incurred as a result of the use of, access to, or denial of
                  access to any data or content.
                </p>
                <br />
                <br />
                <p>
                  <b>5. BRAINALIVE’S OBLIGATIONS FOR CONTENT: </b> BrainAlive
                  will maintain reasonable physical and technical safeguards to
                  prevent unauthorized disclosure of or access to Content, in
                  accordance with industry standards. BrainAlive will notify You
                  if it becomes aware of unauthorized access to Content.
                  BrainAlive will not access, view or process Content except (a)
                  as provided for in this Agreement and in BrainAlive’s Privacy
                  Statement; (b) as authorized or instructed by You, (c) as
                  required to perform its obligations under this Agreement; or
                  (d) as required by Law. BrainAlive has no other obligations
                  with respect to Content.
                </p>
                <br />
                <br />
                <p>
                  <b>6. ELIGIBILITY: </b> You affirm that You are at least 10
                  years of age or less (use FOCII App through a
                  School/University/Institution) and are otherwise fully able
                  and competent to enter into the terms, conditions,
                  obligations, affirmations, representations, and warranties set
                  forth in this Agreement, and to abide by and comply with this
                  Agreement. Your access may be terminated without warning if we
                  believe that You do not belong to the
                  School/University/Institution who bought BrainAlive’s FOCII
                  App or is otherwise ineligible.
                </p>
                <br />
                <br />
                <p>
                  <b>7 INTENDED USE: </b> The FOCII App is intended for
                  commercial use. You may choose to use the FOCII App for other
                  purposes, subject to the terms and limitations of this
                  Agreement. The FOCII App is not intended for use by
                  individuals under the age of 10 unless it is through a School
                  Subscriber (as that term is defined in the BrainAlive products
                  and its associated services Description) using BrainAlive for
                  Education (K-12).
                </p>
                <br />
                <br />
                <p>
                  <b>8. CHARGES AND CANCELLATION: </b> You agree that BrainAlive
                  may charge to Your credit card or other payment mechanism
                  selected by You and approved by BrainAlive (“Your Account”)
                  all amounts due and owing for the FOCII App. Unless stated
                  otherwise, all prices and fees shown by BrainAlive are
                  exclusive of taxes and regulatory fees, BrainAlive products
                  and its associated services, BrainAlive products and its
                  associated services fees, set up fees, subscription fees, or
                  any other fee or charge associated with Your Account. Where
                  applicable, taxes and regulatory fees will be charged on the
                  invoices issued by BrainAlive in accordance with local laws
                  and regulations. The taxes and regulatory fees charged can be
                  changed without notice. All payments made by you to us under
                  this Agreement will be made free and clear of any deduction or
                  withholding, as may be required by law. If any such deduction
                  or withholding (including but not limited to domestic or
                  cross-border withholding taxes) is required on any payment,
                  you will pay such additional amounts as are necessary so that
                  the net amount received by us is equal to the amount then due
                  and payable under this Agreement. We will provide you with
                  such tax forms as are reasonably requested in order to reduce
                  or eliminate the amount of any withholding or deduction for
                  taxes in respect of payments made under this Agreement.
                  BrainAlive may change prices at any time, including changing
                  from a free BrainAlive products and its associated services to
                  a paid BrainAlive products and its associated services and
                  charging for the FOCII App that was previously offered free of
                  charge; provided, however, that BrainAlive will provide you
                  with prior notice and an opportunity to terminate Your Account
                  if BrainAlive changes the price of the FOCII App to which you
                  are subscribed and will not charge you for a previously free
                  App unless you have been notified of the applicable fees and
                  agreed to pay such fees. You agree that in the event
                  BrainAlive is unable to collect the fees owed to BrainAlive
                  for the FOCII App through Your Account, BrainAlive may take
                  any other steps it deems necessary to collect such fees from
                  You and that You will be responsible for all costs and
                  expenses incurred by BrainAlive in connection with such
                  collection activity, including collection fees, court costs,
                  and attorneys’ fees. You further agree that BrainAlive may
                  collect interest at the lesser of 1.5% per month or the
                  highest amount permitted by law on any amounts not paid when
                  due. You may cancel your subscription at any time. If you
                  cancel, you will not be billed for any additional terms of
                  product and its BrainAlive products and its associated
                  services, and BrainAlive products and its associated services
                  will continue until the end of the current Subscription Term.
                  If you cancel, you will not receive a refund for any product
                  and its BrainAlive products and its associated services
                  already paid for.
                </p>
                <br />
                <br />
                <p>
                  <b>9 TERMINATION: </b> You can connect with the BrainAlive
                  Team directly by mailing them at{" "}
                  <a href="mailto:info@braina.live">info@braina.live</a> or
                  reaching out to them through https://brainalive.ai/contact.php
                  for termination process. If you have purchased the FOCII App
                  for a specific term, such termination will be effective on the
                  last day of the then-current term. Your Order Form may provide
                  that a Renewal Term will begin automatically unless either
                  party provides notice of termination at least thirty (30) days
                  prior to the commencement of the next Renewal Term. If You
                  fail to comply with any provision of this Agreement,
                  BrainAlive may terminate this Agreement immediately and retain
                  any fees previously paid by You. Sections 1 and 3 through 20,
                  inclusive, shall survive any termination of this Agreement.
                  Upon any termination of this Agreement, You must cease any
                  further use of the App. If at any time You are not happy with
                  the FOCII App, Your sole remedy is to cease using the App and
                  follow this termination process.
                </p>
                <br />
                <br />
                <p>
                  <b>10 PROPRIETARY RIGHTS: </b> BrainAlive and/or its
                  suppliers, as applicable, retain ownership of all proprietary
                  rights in the FOCII App and in all trade names, trademarks,
                  service marks, logos, and domain names (“FOCII”) associated or
                  displayed with the FOCII App. You may not frame or utilize
                  framing techniques to enclose any BrainAlive FOCII marks or
                  other proprietary information (including images, text, page
                  layout, or form) of BrainAlive without express written
                  consent. You may not use any meta tags or any other “hidden
                  text” utilizing BrainAlive FOCII Marks without BrainAlive
                  express written consent.
                </p>
                <br />
                <br />
                <p>
                  <b>11 COPYRIGHT: </b>You may not post, modify, distribute, or
                  reproduce in any way copyrighted material, trademarks, rights
                  of publicity, or other proprietary rights without obtaining
                  the prior written consent of the owner of such proprietary
                  rights. BrainAlive may deny access to the FOCII App to any
                  User who is alleged to infringe another party’s copyright.
                  Without limiting the foregoing, if You believe that Your
                  copyright has been infringed, please notify BrainAlive as
                  specified here.
                </p>
                <br />
                <br />
                <p>
                  <b>12 EXPORT RESTRICTIONS: </b>You acknowledge that the
                  BrainAlive products and its associated services, or a portion
                  thereof, are subject to the Export Administration Regulations,
                  15 C.F.R. Parts 730-774, of the United States/India and may be
                  subject to other applicable country export control and trade
                  sanctions laws (“Export Control and Sanctions Laws' ).
                  BrainAlive will provide the U.S. and India export
                  classification(s) applicable to its product upon request. You
                  and Your End Users may not access, use, export, re-export,
                  divert, transfer or disclose any portion of the FOCII App or
                  any related technical information or materials, directly or
                  indirectly, in violation of Export Control and Sanctions Laws.
                  You represent and warrant that: (i) You and Your End Users (a)
                  are not citizens of, or located within, a country or territory
                  that is subject to U.S. and India trade sanctions or other
                  significant trade restrictions (including without limitation
                  Cuba, Iran, North Korea, Syria, India and the Crimea region of
                  Ukraine) and that You and Your End Users will not access or
                  use the BrainAlive’s FOCII App, or export, re-export, divert,
                  or transfer the products and its associated services, in or to
                  such countries or territories; (b) are not persons, or owned
                  50% or more, individually or in the aggregate by persons,
                  identified on the U.S. and India Department of the Treasury’s
                  Specially Designated Nationals and Blocked Persons List or
                  Foreign Sanctions Evaders Lists; and (c) are not persons on
                  the U.S. and India Department of Commerce Denied Persons List,
                  Entity List, or Unverified List, or U.S. and India Department
                  of State proliferation-related lists; (ii) You and Your End
                  Users located in China, Russia, or Venezuela are not Military
                  End Users and will not put BrainAlive’s products and its
                  BrainAlive products and its associated services to a Military
                  End Use, as defined in 15 C.F.R. 744.21; (iii) no Content
                  created or submitted by You or Your End Users is subject to
                  any restriction on disclosure, transfer, download, export or
                  re-export under the Export Control and Sanctions Laws; and
                  (iv) You and Your End Users will not take any action that
                  would constitute a violation of, or be penalized under, U.S./
                  India anti boycott laws administered by the Indian Department
                  of Commerce or the Indian Department of the Treasury. You are
                  solely responsible for complying with the Export Control and
                  Sanctions Laws and monitoring them for any modifications
                </p>
                <br />
                <br />
                <p>
                  <b>13 NO HIGH-RISK USE: </b> The FOCII App is not designed or
                  licensed for use in hazardous environments requiring fail-safe
                  controls, including without limitation operation of nuclear
                  facilities, aircraft navigation/communication systems, air
                  traffic control, and life support or weapons systems. The
                  BrainAlive products and its associated services shall not be
                  used for or in any HIGH-RISK environment.
                </p>
                <br />
                <br />
                <p>
                  <b>14 INJUNCTIVE RELIEF: </b>You acknowledge that any use of
                  the FOCII App contrary to this Agreement, or any transfer,
                  sublicensing, copying, or disclosure of technical information
                  or materials related to the BrainAlive products and its
                  associated services, may cause irreparable injury to
                  BrainAlive, its Affiliates, suppliers, and any other party
                  authorized by BrainAlive to resell, distribute, or promote the
                  FOCII App (“Resellers”), and under such circumstances,
                  BrainAlive, its Affiliates, suppliers and Resellers will be
                  entitled to equitable relief, without posting a bond or other
                  security, including, but not limited to, preliminary and
                  permanent injunctive relief.
                </p>
                <br />
                <br />
                <p>
                  <b>15 NO WARRANTIES: </b>YOU UNDERSTAND AND AGREE THAT THE
                  FOCII APP IS PROVIDED “AS IS'' AND BRAINALIVE, ITS AFFILIATES,
                  SUPPLIERS AND RESELLERS EXPRESSLY DISCLAIM ALL WARRANTIES OF
                  ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY
                  WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                  OR NON-INFRINGEMENT. BRAINALIVE, ITS AFFILIATES, SUPPLIERS,
                  AND RESELLERS MAKE NO WARRANTY OR REPRESENTATION REGARDING THE
                  RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE APP,
                  REGARDING THE ACCURACY OR RELIABILITY OF ANY INFORMATION
                  OBTAINED THROUGH THE BRAINALIVE PRODUCTS AND ITS ASSOCIATED
                  SERVICES, OR THAT THE FOCII APP WILL MEET ANY USER’S
                  REQUIREMENTS, OR BE UNINTERRUPTED, TIMELY, SECURE OR
                  ERROR-FREE. USE OF THE FOCII APP IS AT YOUR SOLE RISK. ANY
                  MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH
                  THE USE OF THE FOCII APP IS AT YOUR OWN DISCRETION AND RISK.
                  YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOU RESULTING
                  FROM THE USE OF THE FOCII APP. THE ENTIRE RISK ARISING OUT OF
                  THE USE OR PERFORMANCE OF THE FOCII APP REMAINS WITH YOU.
                  FOCII APP DOES NOT ASSUME ANY RESPONSIBILITY FOR THE RETENTION
                  OF ANY USER INFORMATION OR COMMUNICATIONS BETWEEN USERS. THE
                  FOCII APP CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC
                  RESULTS FROM THE USE OF THE BRAINALIVE’S PRODUCTS AND ITS
                  SERVICES USE IS AT YOUR OWN RISK.
                </p>
                <br />
                <br />
                <p>
                  <b>16 INDEMNIFICATION: </b>You agree to indemnify, defend and
                  hold harmless BrainAlive, its affiliates, officers, directors,
                  employees, consultants, agents, suppliers, and Resellers from
                  any and all third party claims, liability, damages, and/or
                  costs (including, but not limited to, attorneys’ fees) arising
                  from Your use of the FOCII APP, Your violation of this
                  Agreement or the infringement or violation by You or any other
                  user of Your account, of any intellectual property or another
                  right of any person or entity or applicable law.
                </p>
                <br />
                <br />
                <p>
                  <b>17 LIMITATION OF LIABILITY: </b>TO THE MAXIMUM EXTENT
                  PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL BRAINALIVE OR
                  ITS AFFILIATES, SUPPLIERS OR RESELLERS BE LIABLE FOR ANY
                  SPECIAL, INCIDENTAL, INDIRECT, EXEMPLARY, OR CONSEQUENTIAL
                  DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
                  LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF
                  BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS OR DAMAGE)
                  ARISING OUT OF THE USE OF OR INABILITY TO USE THE BRAINALIVE
                  AND ITS SERVICES OR THE PROVISION OF OR FAILURE TO PROVIDE
                  TECHNICAL OR OTHER SUPPORT SERVICES, WHETHER ARISING IN TORT
                  (INCLUDING NEGLIGENCE) CONTRACT OR ANY OTHER LEGAL THEORY,
                  EVEN IF BRAINALIVE, ITS AFFILIATES, SUPPLIERS OR RESELLERS
                  HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY
                  CASE, BRAINALIVE, ITS AFFILIATES’, SUPPLIERS’ AND RESELLERS’
                  MAXIMUM CUMULATIVE LIABILITY AND YOUR EXCLUSIVE REMEDY FOR ANY
                  CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL BE
                  LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU FOR THE
                  BRAINALIVE’S PRODUCTS AND ITS ASSOCIATED SERVICES (IF ANY) IN
                  THE TWELVE (12) MONTHS PRECEDING THE EVENT OR CIRCUMSTANCES
                  GIVING RISE TO SUCH CLAIMS.
                </p>
                <br />
                <p>
                  Because some states and jurisdictions do not allow the
                  exclusion or limitation of liability, the above limitation may
                  not apply to You.
                </p>
                <br />
                <br />
                <p>
                  <b>18 AGREEMENT TO ARBITRATE; WAIVER OF CLASS ACTION: </b> If
                  You are located in the United States,India You agree to
                  resolve disputes only on an individual basis, through
                  arbitration pursuant to the provisions of Exhibit A.{" "}
                  <b>
                    The parties expressly waive any right to bring any action,
                    lawsuit, or proceeding as a class or collective action,
                    private attorney general action, or any other proceeding in
                    which any party acts or proposes to act in a representative
                    capacit:{" "}
                  </b>
                </p>
                <br />
                <br />
                <p>
                  <b>19 PRIVACY AND OTHER POLICIES: </b>Use of the FOCII App is
                  also subject to BrainAlive Privacy Statement, a link to which
                  can be found by selecting “Privacy and Legal Policies” in the
                  footer of BrainAlive FOCII App Download web page. The Privacy
                  Statement and all policies noticed, are incorporated into this
                  Agreement by this reference. Furthermore, if Your Use of the
                  FOCII App requires BrainAlive to process any personally
                  identifiable information (“PII” or “Personal Data”) BrainAlive
                  shall do so at all times in compliance with our BrainAlive
                  Global Data Processing Addendum which is incorporated in these
                  Terms of BrainAlive products and its associated services.
                  Additionally, You understand and agree that BrainAlive may
                  contact You via e-mail or otherwise with information relevant
                  to Your use of the BrainAlive products and its associated
                  services, regardless of whether You have opted out of
                  receiving marketing communications or notices.
                </p>
                <br />
                <br />
                <p>
                  <b>20 MISCELLANEOUS: </b>
                </p>
                <br />
                <p>
                  <b>1 Choice of Law and Forum: </b>This Agreement shall be
                  governed by and construed under the laws of the State of
                  California, U.S.A, India as applied to agreements entered into
                  and to be performed in India by Indian residents. Except as
                  provided in Exhibit A, the Parties consent to the exclusive
                  jurisdiction and venue of the state courts located in and
                  serving Santa Clara County, California, the federal courts in
                  the Northern District of California and India.
                </p>
                <p>
                  <b>2 Contracting Entity: </b> In the event, Your BrainAlive
                  FOCII App account reflects a bill to/sold to address in India,
                  the contracting entity under these TOS shall be BrainAlive’s
                  Affiliate, or BrainAlive itself.
                </p>
                <p>
                  <b>3 Waiver and Severability: </b> Failure by either Party to
                  exercise any of its rights under, or to enforce any provision
                  of, this Agreement will not be deemed a waiver or forfeiture
                  of such rights or ability to enforce such provision. If any
                  provision of this Agreement is held by a court of competent
                  jurisdiction to be illegal, invalid, or unenforceable, that
                  provision will be amended to achieve as nearly as possible the
                  same economic effect of the original provision and the
                  remainder of this Agreement will remain in full force and
                  effect.
                </p>
                <p>
                  <b>4 General Provisions: </b> This Agreement embodies the
                  entire understanding and agreement between the Parties
                  respecting the subject matter of this Agreement and supersedes
                  any and all prior understandings and agreements between the
                  Parties respecting such subject matter, except that if You or
                  Your company have executed a separate written agreement or you
                  have signed an order form referencing a separate agreement
                  governing your use of the FOCII app, then such agreement shall
                  control to the extent that any provision of this Agreement
                  conflicts with the terms of such agreement. BrainAlive may
                  elect to change or supplement the terms of this Agreement from
                  time to time at its sole discretion. BrainAlive will exercise
                  commercially reasonable business efforts to provide notice to
                  You of any material changes to this Agreement. Within ten (10)
                  business days of posting changes to this Agreement (or ten
                  (10) business days from the date of notice, if such is
                  provided), they will be binding on You. If You do not agree
                  with the changes, You should discontinue using the BrainAlive
                  products and its associated services. If You continue using
                  the BrainAlive products and its associated services after such
                  a ten-business-day period, You will be deemed to have accepted
                  the changes to the terms of this Agreement. In order to
                  participate in certain features of FOCII App, You may be
                  notified that You are required to download software and/or
                  agree to additional terms and conditions. Unless expressly set
                  forth in such additional terms and conditions, those
                  additional terms are hereby incorporated into this Agreement.
                  This Agreement has been prepared in the English Language and
                  such version shall be controlling in all respects and any
                  non-English version of this Agreement is solely for
                  accommodation purposes.
                </p>
                <br />
                <br />
                <p>
                  <b>Exhibit A: </b>
                </p>
                <p>
                  <b>Binding Arbitration: </b>
                  <br /> This Exhibit A to the TOS describes the further
                  provisions which apply to the Binding Arbitration and Class
                  Action Waiver.
                </p>
                <ul>
                  <li>
                    <p>
                      <b>Disputes: </b> A dispute is any controversy between You
                      and BrainAlive concerning the FOCII App, any software
                      related to the FOCII App, the price of the FOCII App, Your
                      account, BrainAlive’s advertising, marketing, or
                      communications, Your purchase transaction or billing, or
                      any term of this Agreement, under any legal theory
                      including contract, warranty, tort, statute, or
                      regulation, except disputes relating to the enforcement or
                      validity of Your or BrainAlive’s intellectual property
                      rights. As part of the best efforts process to resolve
                      disputes, and prior to initiating arbitration proceedings,
                      each party agrees to provide notice of the dispute to the
                      other party, including a description of the dispute, what
                      efforts have been made to resolve it, and what the
                      disputing party is requesting as resolution, to
                      info@braina.live.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Small Claims Court Available: </b> You may initiate an
                      action in your local Small Claims Court if You meet the
                      court’s requirements. However, if such a claim is
                      transferred, removed or appealed to a different court,
                      BrainAlive reserves the right to require arbitration.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Arbitration Procedure: </b> Disputes not resolved
                      pursuant to Section A or B shall be resolved through
                      arbitration. The Indian Arbitration Association (“IAA”)
                      will conduct any arbitration under its Commercial
                      Arbitration Rules. For more information, Arbitration
                      hearings will take place in the federal judicial district
                      of Your primary business location. A single arbitrator
                      will be appointed. The arbitrator must: (a) follow all
                      applicable substantive Law; (b) follow applicable statutes
                      of limitations; (c) honor valid claims of privilege; (d)
                      issue a written decision including the reasons for the
                      award. The arbitrator may award damages, declaratory or
                      injunctive relief, and costs (including reasonable
                      attorneys’ fees). Any arbitration award may be enforced
                      (such as through a judgment) in any court with
                      jurisdiction. Under AIL Rules, the arbitrator rules on his
                      or her own jurisdiction, including the arbitrability of
                      any claim; however, a court has exclusive authority to
                      enforce the prohibition on arbitration on a class-wide
                      basis or in a representative capacity .
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Arbitration Fees: </b> If You are unable to afford the
                      arbitration costs, BrainAlive will advance those costs to
                      You, subject to the arbitrator’s determination if costs
                      should be reimbursed to BrainAlive if it prevails. For
                      disputes involving more than $75,000, the AIL rules will
                      govern payment of filing fees and the AIL’s and
                      arbitrator’s fees and expenses.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Conflict with AIL Rules: </b> This Agreement governs if
                      there is a conflict with the AIL’s Commercial Arbitration
                      Rules.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Requirement to File Within One Year: </b>{" "}
                      Notwithstanding any other statute of limitations, a claim
                      or dispute under this Agreement must be filed in Small
                      Claims Court or noticed for arbitration within one year of
                      when it could first be filed, or such claim will be
                      permanently barred.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Severability: </b> If the class action waiver is found
                      to be illegal or unenforceable as to all or some parts of
                      a dispute, then those parts will not be arbitrated but
                      will be resolved in court, with the balance resolved
                      through arbitration. If any provision of this Exhibit A is
                      found to be illegal or unenforceable, then that provision
                      will be severed; however, the remaining provisions shall
                      still apply and shall be interpreted to as nearly as
                      possible achieve the original intent of this Exhibit,
                      inclusive of the severed provision.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Index;
