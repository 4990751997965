import React from "react";
import "./BlogHeader.css";
import { Link } from "react-router-dom";

const BlogHeader = ({ recentBlogs }) => {
    console.log(recentBlogs)
  return (
    <Link
      to={`/blog/${recentBlogs[0]?.blogTitle?.replace(/\s+/g, "_")}`}
      className="blog-hero-bg"
      style={{
        background: `linear-gradient(263deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.26) 100%), url(${recentBlogs[0]?.coverImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></Link>
  );
};

export default BlogHeader;