import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import VideoManage from "../../../components/Blogs/Videomanage/VideoManage";
import Footer from "../../../components/Footer/Footer";

const Videomanaging = () => {
  useEffect(() => {
    document.title =
      "Is your educational video effectively managing the cognitive load of your viewers? | Braina Live Blog";
  }, []);
  return (
    <div>
      <Navbar />
      <VideoManage />
      <Footer />
    </div>
  );
};

export default Videomanaging;
