import axios from "axios";
import { blog_api_url } from "../config";

const getAllBlogs = async () => {
  return axios.get(blog_api_url + `getallBlogs`).then((res) => res);
};
const getDynamicBlog = async (title) => {
  return axios.get(blog_api_url + `blog/` + title).then((res) => res);
};

const userService = {
  getAllBlogs,
  getDynamicBlog,
};
export default userService;
