import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import OnlineTeachings from "../../../components/Blogs/OnlineTeachings/OnlineTeachings";

const OnlineTeaching = () => {
  return (
    <div>
      <Navbar />
      <OnlineTeachings />
      <Footer />
    </div>
  );
};

export default OnlineTeaching;
