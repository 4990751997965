import React, { useEffect } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import fociiHead from "../../assets/focii-head.png";
import fociiHeadMob from "../../assets/focii-head-mob.png";
import RightArrow from "../../assets/icon-li.svg";
import PlayIcon from "../../assets/playIcon.png";
import max from "../../assets/max.png";
import min from "../../assets/min.png";
import focii1 from "../../assets/focii1.png";
import focii2 from "../../assets/focii2.png";
import fociiMp4 from "../../assets/fociiMp4.mp4";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import ReadyBanner from "../Banner/ReadyBanner";
import EarnBanner from "../Banner/EarnBanner";


const Focii = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section>
      <div className="top-section-bg">
        <div className="focii-section">
          <div className="container">
            <div className="row w-100">
              <div className="col-lg-6 pt-4">
                <div className="">
                  <div className="smallFocii">FOCII</div>
                  <h1>Grow Your Audience With Better Content</h1>
                  <p>
                    <span style={{ fontWeight: 500 }}>I</span>ncrease audience
                    retention rate by improving their engagement through
                    enhanced content and effective delivery. FOCII enables you
                    to deliver the best value for everyone’s time (creator &
                    consumer).
                  </p>
                  <Link to="/contact" className="btn btn-dark"> Book a Demo</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <img src={fociiHead} alt="focii" className="img-fluid" />
                {/* <picture>
                  <source media="(min-width:650px)" srcset={fociiHead} />
                  <source media="(min-width:465px)" srcset={fociiHeadMob} />
                  <img srcset={fociiHeadMob} alt="focii" className="img-fluid" />
                </picture> */}
                {/* <!-- <img src="./img/focii-head.png" className="img-fluid" alt=""> --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* main */}
     <ReadyBanner/>
      {/* main */}
      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={max} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-4 pe-sm-0 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>Maximize Audience Engagement In Realtime</h4>
              <p>
                Highlight memorability of attention, engagement, speech
                effectiveness and much more
              </p>
              <ul>
                <li>
                  <i className="bi bi-check">
                    {" "}
                    <img src={RightArrow} />{" "}
                  </i>
                  Identify the causes of dropping engagement and address it as
                  you see fit
                </li>
                <li>
                  <i className="bi bi-check">
                    {" "}
                    <img src={RightArrow} />
                  </i>
                  Modify your speech in to enhance session effectiveness
                </li>
                <li>
                  <i className="bi bi-check">
                    {" "}
                    <img src={RightArrow} />
                  </i>
                  Optimize audience engagement with on the go insights
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* main */}
      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={min} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-4 px-4 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>Minimize Audience Distraction For Future Content</h4>
              <p>
                Highlight measurability of attention. engagement, speech
                effectiveness, session duration and much more, post your
                session.
              </p>
              <ul>
                <li>
                  <i className="bi bi-check">
                    {" "}
                    <img src={RightArrow} />
                  </i>
                  Highlight correlation between different CV parameters
                </li>
                <li>
                  <i className="bi bi-check">
                    {" "}
                    <img src={RightArrow} />
                  </i>
                  Correlations between different speech insights
                </li>
                <li>
                  <i className="bi bi-check">
                    {" "}
                    <img src={RightArrow} />
                  </i>
                  Heatmap for the session and key outcomes
                </li>
              </ul>
              <p>and much more...</p>
            </div>
          </div>
        </div>
      </div>



      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="video-box">
              {/* <video src={fociiMp4} autoPlay="true" /> */}
              <video id="bannerVideo"  autoPlay muted loop controls>
                <source src={fociiMp4} type="video/mp4" />
              </video>
                {/* <img src={PlayIcon} alt="" /> */}
              </div>
            </div>
            <div
              className="col-lg-6 pt-4 px-4 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>Know What Is Working For Your Audience And What Isn’t</h4>
              <p>
                Discover your content’s impact on your audience’s attention,
                sentiment and engagement
              </p>
              <ul>
                <li>
                  <i className="bi bi-check">
                    <img src={RightArrow} />
                  </i>
                  Optimize you content delivery in real-time
                </li>
                <li>
                  <i className="bi bi-check">
                    <img src={RightArrow} />
                  </i>
                  Enhance your content with heatmaps and engagement scores
                </li>
                <li>
                  <i className="bi bi-check">
                    <img src={RightArrow} />
                  </i>
                  See how various metrics are related to overall audience
                  engagement
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* main */}
      {/* <div className="autumn">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6 order-md-last">
              <div className="video-box" style={{ backgroundColor: "#fff" }}>
                <img src={PlayIcon} alt="" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="video-text">
                Watch how FOCII is enabling so many virtual meeting hosts take
                their live session to the next level
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* main */}
      <div className="desk-meeting">
        <div className="container">
          <h3>Now Everyone Gets To Make The Most Out Of Their Efforts</h3>
          <div className="row">
            <div className="col-sm-6">
              <div className="boxes me-sm-3">
                <img src={focii1} className="img-fluid" alt="" />
                <p>
                  Meeting host or content creators get to make more engaging
                  content leading to better audience retention.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="boxes ms-sm-3">
                <img src={focii2} className="img-fluid" alt="" />
                <p>
                  Meeting participants or content consumers get to access
                  better, more engaging content leading to an immersive media
                  experience.
                </p>
              </div>
            </div>
          </div>
          <div className="audi-content text-center pb-0">
            <h4>Better value for all</h4>
          </div>
        </div>
      </div>
      <EarnBanner/>
      <div className="red-section">
        <div className="container">
          <div className="reasoly-box">
            <h2>Ready to grow your audience with better retention rate?</h2>
            <Link to="/contact" className="btn btn-dark"> Book a Demo</Link>
          </div>
        </div>
      </div>
     
    </section>
  );
};

export default Focii;
