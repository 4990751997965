import React from "react";
import "./KeyFeatures.css";
import googleChromeLogo from "../../../assets/focii-youtube/GoogleChromeLogo.png";
import handCoins from "../../../assets/focii-youtube/HandCoins.png";
import shieldCheck from "../../../assets/focii-youtube/ShieldCheck.png";
import userFocus from "../../../assets/focii-youtube/UserFocus.png";

const KeyFeatures = () => {
  const featuresData = [
    {
      id: 1,
      img: handCoins,
      title: "Earn Rewards Effortlessly",
      description:
        "Sit back and watch your crypto rewards accumulate effortlessly as you indulge in YouTube videos.",
    },
    {
      id: 2,
      img: googleChromeLogo,
      title: "Seamless Integration",
      description:
        "Our extension seamlessly integrates with your Chrome browser, requiring no additional effort from you.",
    },
    // {
    //   id: 3,
    //   img: shieldCheck,
    //   title: "Secure Transactions",
    //   description:
    //     "Rest assured, all crypto transactions within the extension are secured with Blockchain technology.",
    // },
    {
      id: 4,
      img: userFocus,
      title: "User-Friendly Interface",
      description:
        "Experience hassle-free navigation and intuitive controls with our user-friendly interface.",
    },
  ];

  return (
    <section className="container">
      <h1 className="key-features-title">Key Features at a Glance</h1>
      <div
        style={{ display: "flex", justifyContent: "center",alignItems:"center", gap: "48px" }}
        className="row"
      >
        {featuresData.map((item) => (
          <div className="key-features-card">
            <img src={item.img} alt="" />
            <h5>{item.title}</h5>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KeyFeatures;
