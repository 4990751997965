import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import YoutubeHeader from "../components/FociiYoutube/YoutubeHeader/YoutubeHeader";
import KeyFeatures from "../components/FociiYoutube/KeyFeatures/KeyFeatures";
import GetRewarded from "../components/FociiYoutube/GetRewarded/GetRewarded";
import Privacy from "../components/FociiYoutube/Privacy/Privacy";
import EarnMoreBanner from "../components/FociiYoutube/EarnMorebanner/EarnMoreBanner";

const FociiYoutube = () => {
  useEffect(() => {
    document.title = "FOCII for Youtube";
  }, []);
  return (
    <>
      <Navbar />
      <YoutubeHeader />
      <KeyFeatures />
      <GetRewarded />
      <Privacy />
      <EarnMoreBanner/>
      <Footer />
    </>
  );
};

export default FociiYoutube;
