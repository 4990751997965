
// export default Carosuel;
import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

export default function Example(props)
{
    const {items} = props;
    console.log(props)
    // if(items[0].description){
    //   // console.log(items[0].description)
    // }
    return (
        <div style={{padding:'15px 110px'}}>
          <Carousel >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
        </div>
    )
}

function Item(props)
{
    return (
        <Paper>
            {props?.item?.name && <p><strong>{props.item.name}</strong></p>}
            {props?.item?.description && <p>{props.item.description}</p>}
            <img src={props.item.image} alt='lll' style={{width:"100%"}}/>

            
        </Paper>
    )
}