import './ReadyBanner.css'
import frame from './Frame8906.svg'

const ReadyBanner =()=>{

    const handleImageClick = () => {
        // Open the external link in a new tab
        window.open('https://focii.brainalive.ai/download-app', '_blank');
      };
      
    return(<div className='readyBanner-container firstBanner'>

        <div className="readyBanner">
                <img src={frame} alt="" onClick={handleImageClick} className="earnBannerClick"/>
        </div>
    
    </div>)
}

export default ReadyBanner