import React from "react";

const ArtificialIntelligence = () => {
  return (
    <section className="secondary-light-bg">
      <div className="container">
        <div className="science-features-container">
          <div className="science-features">
            <h1 className="primary-dark-text text-center">
              Artificial Intelligence | BAAI
            </h1>
            <p className="primary-dark-text">
              BAAI leverages data transformation approach to convert raw
              interaction data into real-time user engagement insights. Our
              insights are evidence based and supported by credible and
              quantifiable research. We have a dedicated team of exceptional
              data scientists and engineers to make all of this a reality.
            </p>
          </div>

          <div className="row ">
            <div className="col-lg-6 science-feature cv-nlp">
              <h1 className="primary-dark-text">Computer Vision (CV)</h1>
              <p className="primary-dark-text">
                BAAI uses our Computer Vision algorithms to track your pupil
                movements, facial features and postures to assess the engagement
                level in real time.
              </p>
            </div>
            <div className="col-lg-6 science-feature nlp-cv">
              <h1 className="primary-dark-text">
                Natural Language Processing (NLP)
              </h1>
              <p className="primary-dark-text">
                Our NLP algorithm gathers unique audio data to generate
                actionable speech analytics that improve overall user
                engagement.
              </p>
            </div>
          </div>

          <div className="launch-and-dataset-btns row">
            {/* <a
              className="launch-demo-btn gray-background-bg primary-light-text"
              href="/tracking/calibration.html"
              target="_blank"
            >
              Launch Live Demo
            </a> */}
            <a
              href="https://forms.gle/TgmmefJPxnwJVroG6"
              target="_blank"
              className="get-dataset-btn primary-dark-text"
            >
              <span>Get Brainalive Dataset</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArtificialIntelligence;
