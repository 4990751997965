import React, { useEffect } from "react";
import "../css/style.css";
import "../css/responsive.css";
import Navbar from "../components/Navbar/Navbar";
import Home from "../components/Home/Home";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
  useEffect(() => {
    document.title = "Home | BrainAlive";
  }, []);
  return (
    <>
      <Navbar />
      <Home />
      <Footer />
    </>
  );
};

export default HomePage;
