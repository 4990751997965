import React from "react";
import "./BrainWave.css";
import delta from "../../../assets/science/delta.gif";
import theta from "../../../assets/science/theta.gif";
import alpha from "../../../assets/science/alpha.gif";
import beta from "../../../assets/science/beta.gif";
const BrainWave = () => {
  const data = [
    {
      id: 1,
      img: delta,
      title: "Delta Band (1-4 Hz)",
      description:
        "Delta irregularity signifies brain disorders, learning problems, inability to think, and ADHD.",
    },
    {
      id: 2,
      img: theta,
      title: "Theta Band (4-8 Hz)",
      description:
        "Theta band strength signifies usage of memory and navigation.",
    },
    {
      id: 3,
      img: alpha,
      title: "Alpha Band (8-12 Hz)",
      description:
        "Increased levels of Alpha band signifies more relaxation both physically and mentally.",
    },
    {
      id: 4,
      img: beta,
      title: "Beta Band (12-25 Hz)",
      description:
        "Beta power becomes stronger during motor activities that requires fine body part movements.",
    },
  ];
  return (
    <section className="primary-light-bg">
      <div className="container">
        <div className="brain-wave-container science-features-container">
          <h1 className="primary-dark-text text-center">
            Brain Wave Oscillations
          </h1>
          <div className="row">
            {data.map((data) => (
              <div className="col-lg-6">
                <div className="brain-wave-card secondary-light-bg">
                  <img className="wave-images" src={data.img} alt="" />
                  <h3 className="primary-dark-text">{data.title}</h3>
                  <p className="primary-dark-text">{data.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrainWave;
