import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import userService from "../../services/userServices";
import { blogData } from "../../components/Blog/BlogData/Blogdata";
import BlogHeader from "../../components/Blog/BlogHeader/BlogHeader";
import PopularArticles from "../../components/Blog/PopularArticles/PopularArticles";
import Blogs from "../../components/Blog/Blogs/Blogs";
import { LoadingContext } from "../../App";

const BlogPage = () => {
  const { setLoading } = useContext(LoadingContext);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [oldBlog, setOldBlog] = useState([]);

  useEffect(() => {
    document.title = "Blog | BrainAlive";
    setOldBlog(blogData);
    getBlogData();
  }, []);

  const getBlogData = async () => {
    try {
      setLoading(true);

      const response = await userService.getAllBlogs();

      if (response.error) {
        alert(response?.error?.message || "Something went worng!");
      } else {
        setRecentBlogs(response?.data);
        console.log(response?.data?.reverse());
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };
  console.log(recentBlogs);

  return (
    <>
      <Navbar />
      <BlogHeader recentBlogs={recentBlogs.reverse()} />
      <PopularArticles recentBlogs={recentBlogs.reverse()} oldBlog={oldBlog} />
      <Blogs recentBlogs={recentBlogs.reverse()} oldBlog={oldBlog} />
      <Footer />
    </>
  );
};

export default BlogPage;
