import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import BCI from "../components/BCI/BCI";
import Footer from "../components/Footer/Footer";

const BciPage = () => {
  useEffect(() => {
    document.title = "Headsets | BrainAlive";
  }, []);
  return (
    <>
      <Navbar />
      <BCI />
      <Footer />
    </>
  );
};

export default BciPage;
