import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import FociiPage from "../pages/FociiPage";
import BciPage from "../pages/BciPage";
import AbhaPage from "../pages/AbhaPage";
import SciencePage from "../pages/SciencePage";
import ContactPage from "../pages/ContactPage";
import ScrollToTop from "../components/ScrolToTop";
import Privacy from "../pages/Privacy/index";
import Terms from "../pages/Terms/index";
import BlogPage from "../pages/BlogPage/BlogPage";
import LearnAnalytics from "../pages/BlogPosts/LearnAnalytics/LearnAnalytics";
import OnlineLearning from "../pages/BlogPosts/OnlineLearning/OnlineLearning";
import OnlineTeaching from "../pages/BlogPosts/OnlineTeaching/OnlineTeaching";
import Videomanaging from "../pages/BlogPosts/VideoManaging/Videomanaging";
import DynamicBlogs from "../pages/BlogPosts/DynamicBlogs/DynamicBlogs";
import DynamicTagBlogs from "../pages/BlogPosts/DynamicTagBlogs/DynamicTagBlogs";
import TagRelatedBlog from "../pages/BlogPosts/TagRelatedBlog/TagRelatedBlog";
import TrackAnalytics from "../helpers/googleAnalytics";
import DownloadExtension from "../pages/DownloadPage/DownloadExtension";
import FociiYoutube from "../pages/FociiYoutube";

const NavigationRouter = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/focii" element={<FociiPage />} />
        <Route path="/focii_youtube" element={<FociiYoutube />} />
        <Route path="/bci" element={<BciPage />} />
        <Route path="/abha" element={<AbhaPage />} />
        <Route path="/science" element={<SciencePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/learn_analytics" element={<LearnAnalytics />} />
        <Route path="/blog/online_teaching" element={<OnlineTeaching />} />
        <Route path="/blog/online_learning" element={<OnlineLearning />} />
        <Route path="/blog/video_managing" element={<Videomanaging />} />
        <Route path="/blog/:title" element={<DynamicBlogs />} />
        <Route path="/blog/tag/:tagName" element={<DynamicTagBlogs />} />
        <Route path="/blogs/tag/:tag" element={<TagRelatedBlog />} />
        <Route
          path="/download-chrome-extension"
          element={<DownloadExtension />}
        />
      </Routes>
    </>
  );
};

export default TrackAnalytics(NavigationRouter);
