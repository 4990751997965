import React, { useContext } from "react";
import "./Contact.css";
import { useForm } from "react-hook-form";
import { api_url } from "../../config";
import axios from "axios";
import { LoadingContext } from "../../App";
import { message } from "antd";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { setLoading } = useContext(LoadingContext);
  const onSubmit = async (data) => {
    // getting full number
    const number = `${data.extension}${data.phone}`;

    // getting local date and time
    const d = new Date();
    let time = d.toLocaleTimeString();
    let day = new Date().toLocaleString("en-US", {
      day: "2-digit",
    });
    let month = new Date().toLocaleString("en-US", {
      month: "long",
    });
    const year = new Date().getFullYear();
    const fullDate = `${day} ${month} ${year}, ${time}`;

    // full message object
    const contactData = {
      date: fullDate,
      fullName: data.fullName,
      companyName: data.companyName,
      email: data.email,
      contactNo: number,
      selectedProduct: data.product,
      selectedEnquiry: data.inquiryType,
      message: data.message,
    };
    console.log(contactData);

    setLoading(true);
    await axios
      .post(`${api_url}/auth/v1/contact`, contactData, {
        "Content-Type": "application/json",
      })

      .then(function (response) {
        setLoading(false);
        message.destroy();
        message.success("Thanks! We will get in touch with you soon.");
        reset();
      })
      .catch(function (error) {
        setLoading(false);
        message.destroy();
        message.error("Something went wrong! Please try again.");
      });
  };

  return (
    <>
      <section className="primary-light-bg contact-container">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="contact-left-content">
                  <h1 className="contact-hello secondary-dark-text">Hello!</h1>
                  <h6 className="love-to-hear secondary-dark-text">
                    We’d Love To Hear From You.
                  </h6>
                  <p className="contact-description secondary-dark-text">
                    If you have a sales question, a press inquiry or anything
                    else you would like to ask us, please send a message and
                    we’ll get back to you.
                  </p>
                </div>
              </div>
              {/* Contact form */}
              <div className="col-lg-6 ">
                <div className="contact-form-div secondary-light-bg">
                  <h2 className="lets-talk black-gray">Let's Talk</h2>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-6">
                        <div className="input-underline">
                          <p className="contact-input-label dark-gray">
                            Full Name
                          </p>
                          <input
                            className="primary-input dark-gray"
                            placeholder="Full Name*"
                            type="text"
                            {...register("fullName", { required: true })}
                          />
                        </div>
                        {errors.fullName && (
                          <span className="validation-text">
                            Please enter your name
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 input-field-div">
                        <div className="input-underline">
                          <p className="contact-input-label">Company Name</p>
                          <input
                            name="lastName"
                            className="primary-input"
                            placeholder="Company name*"
                            type="text"
                            {...register("companyName", { required: true })}
                          />
                        </div>
                        {errors.companyName && (
                          <span className="validation-text">
                            Please enter your company name
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-6">
                        <div className="input-underline">
                          <p className="contact-input-label">Email</p>
                          <input
                            className="primary-input"
                            placeholder="Email*"
                            type="email"
                            {...register("email", { required: true })}
                          />
                        </div>
                        {errors.email && (
                          <span className="validation-text">
                            Please enter your work email
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="input-underline ">
                          <p className="contact-input-label">Contact No.</p>
                          <div className="d-flex">
                            <div className="extension-options-width">
                              <select
                                style={{
                                  padding: "5px",
                                  borderRight: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  // paddingLeft: "15px",
                                }}
                                id="enquiry"
                                className="primary-input "
                                name="extension"
                                type="selelct"
                                {...register("extension", { required: true })}
                              >
                                <option className="select-type" value="+91">
                                  +91{" "}
                                </option>
                                {/* <option  value="+91">+91</option> */}
                                <option value="+880">+880</option>
                                <option value="+966">+966</option>
                                <option value="566">+566</option>
                              </select>
                            </div>
                            <div className="number-input-field">
                              <input
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                                className="primary-input"
                                placeholder="##########"
                                type="tel"
                                maxLength={15}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                {...register("phone", {
                                  required: false,
                                  pattern: {
                                    value: /^[0-9]{10,15}$/,
                                    message:
                                      "Please enter a valid phone number",
                                  },
                                  minLength: {
                                    value: 10,
                                    message:
                                      "Please enter a valid phone number",
                                  },
                                  maxLength: {
                                    value: 15,
                                    message:
                                      "Please enter a valid phone number",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        {/* {errors.extension && (
                          <span className="validation-text">
                            Please enter a valid phone number with area code
                          </span>
                        )} */}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-6">
                        <div className="input-underline">
                          <p className="contact-input-label">Select Product</p>
                          <select
                            id="enquiry"
                            className="primary-input"
                            name="product"
                            type="selelct"
                            {...register("product")}
                          >
                            <option className="select-type" value="">
                              Select Product{" "}
                            </option>
                            <option value="FOCII">FOCII</option>
                            <option value="ABHA">ABHA</option>
                            <option value="BA3E">BA3E</option>
                            <option value="BA9E">BA9E</option>
                          </select>
                        </div>
                        {/* {errors.product && (
                          <span className="validation-text">
                            Please enter the product name
                          </span>
                        )} */}
                      </div>
                      <div className="col-md-6">
                        <div className="input-underline">
                          <p className="contact-input-label">Type of Enquiry</p>
                          <select
                            id="enquiry"
                            className="primary-input"
                            name="enquiry"
                            type="selelct"
                            {...register("inquiryType", { required: true })}
                          >
                            <option className="select-type" value="">
                              Select Type of Enquiry*{" "}
                            </option>
                            <option value="Sales">Beta Registration</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Press">Press </option>
                            <option value="Press">Free Demo </option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        {errors.product && (
                          <span className="validation-text">
                            Please enter the inquiry type
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row text-area-input">
                      <div className="col-md-12">
                        <div className="input-underline">
                          <p className="contact-input-label">Message</p>
                          <textarea
                            className="message-input"
                            placeholder="Type your message here....."
                            cols="55"
                            rows="2"
                            {...register("message", { required: true })}
                          ></textarea>
                        </div>
                        {errors.message && (
                          <span className="validation-text">
                            Please enter a valid message
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <button
                        type="submit"
                        className="submit-btn neutral-black-bg primary-light-text"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
