import React from "react";
import "./BrainCarousel.css";

import maths1 from "../../../assets/science/maths1.png";
import maths2 from "../../../assets/science/maths2.png";
import maths3 from "../../../assets/science/maths3.png";
import maths4 from "../../../assets/science/maths4.png";
import maths5 from "../../../assets/science/maths5.png";

import lang1 from "../../../assets/science/lang1.png";
import lang2 from "../../../assets/science/lang2.png";
import lang3 from "../../../assets/science/lang3.png";
import lang4 from "../../../assets/science/lang4.png";
import lang5 from "../../../assets/science/lang5.png";

import hand1 from "../../../assets/science/hand1.png";
import hand2 from "../../../assets/science/hand2.png";
import hand3 from "../../../assets/science/hand3.png";
import hand4 from "../../../assets/science/hand4.png";
import hand5 from "../../../assets/science/hand5.png";

import feet1 from "../../../assets/science/feet1.png";
import feet2 from "../../../assets/science/feet2.png";
import feet3 from "../../../assets/science/feet3.png";
import feet4 from "../../../assets/science/feet4.png";
import feet5 from "../../../assets/science/feet5.png";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  Thumbs,
  FreeMode,
} from "swiper";
import "swiper/css";
// import "swiper/scss/navigation";
// import "swiper/scss/pagination";
// import "swiper/scss/scrollbar";
// import "swiper/scss/effect-creative";
// import "swiper/scss/thumbs";
import { useState } from "react";

const BrainCarousel = () => {
  const [brainImgType, setBrainImgType] = useState("maths");
  const [activeThumb, setActiveThumb] = useState();
  const mathsDetails = [
    {
      id: 1,
      img: maths1,
    },
    {
      id: 2,
      img: maths2,
    },
    {
      id: 3,
      img: maths3,
    },
    {
      id: 4,
      img: maths4,
    },
    {
      id: 5,
      img: maths5,
    },
  ];

  const langDetails = [
    {
      id: 1,
      img: lang1,
    },
    {
      id: 2,
      img: lang2,
    },
    {
      id: 3,
      img: lang3,
    },
    {
      id: 4,
      img: lang4,
    },
    {
      id: 5,
      img: lang5,
    },
  ];

  const handDetails = [
    {
      id: 1,
      img: hand1,
    },
    {
      id: 2,
      img: hand2,
    },
    {
      id: 3,
      img: hand3,
    },
    {
      id: 4,
      img: hand4,
    },
    {
      id: 5,
      img: hand5,
    },
  ];

  const feetDetails = [
    {
      id: 1,
      img: feet1,
    },
    {
      id: 2,
      img: feet2,
    },
    {
      id: 3,
      img: feet3,
    },
    {
      id: 4,
      img: feet4,
    },
    {
      id: 5,
      img: feet5,
    },
  ];
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <section className="primary-light-bg">
      <div className="">
        <div className="container">
          <div className="row science-features-container">
            <div className="carousel-heading-text">
              <h1 className="primary-dark-text text-center">
                Brain Computer Interface
              </h1>
              <p className="primary-dark-text">
                Our EEG + fNIRS headset, captures physiological signals of your
                brain to understand the cerebral circuit activations for
                corresponding user activities.
              </p>
            </div>
            <div className="col-lg-4 tab-device-flex hide-for-mobile-device">
              <div
                onClick={() => setBrainImgType("maths")}
                className={`brain-angle-view-div primary-dark-text ${
                  brainImgType === "maths" && "activated-brain-tab"
                }`}
              >
                <h2 className="brain-angle-title">
                  Mathematics and <br /> Logic
                </h2>
              </div>

              <div
                onClick={() => setBrainImgType("hand")}
                className={`brain-angle-view-div primary-dark-text ${
                  brainImgType === "hand" && "activated-brain-tab"
                }`}
              >
                <h2 className="brain-angle-title">
                  Motor Movement <br /> Hands
                </h2>
              </div>
            </div>

            <div className="col-lg-4 order-sm-1-0">
              <div className="swiper-images-div">
                <main>
                  <Swiper
                    modules={[
                      Navigation,
                      Pagination,
                      A11y,
                      Autoplay,
                      Thumbs,
                      FreeMode,
                    ]}
                    autoplay={{ delay: 3000 }}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    loop
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      480: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 1,
                      },
                      1024: {
                        slidesPerView: 1,
                      },
                      1440: {
                        slidesPerView: 1,
                      },
                      2560: {
                        slidesPerView: 1,
                      },
                    }}
                  >
                    {brainImgType === "maths" && (
                      <div>
                        {mathsDetails.map((data, index) => (
                          <SwiperSlide key={index}>
                            <div
                              key={data.id}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <div>
                                <img
                                  className="swiper-image"
                                  src={data.img}
                                  alt=""
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </div>
                    )}
                    {brainImgType === "lang" && (
                      <div>
                        {langDetails.map((data) => (
                          <SwiperSlide>
                            <div
                              key={data.id}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <div>
                                <img
                                  className="swiper-image"
                                  src={data.img}
                                  alt=""
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </div>
                    )}
                    {brainImgType === "hand" && (
                      <div>
                        {handDetails.map((data) => (
                          <SwiperSlide>
                            <div
                              key={data.id}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <div>
                                <img
                                  className="swiper-image"
                                  src={data.img}
                                  alt=""
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </div>
                    )}
                    {brainImgType === "feet" && (
                      <div>
                        {feetDetails.map((data) => (
                          <SwiperSlide>
                            <div
                              key={data.id}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <div>
                                <img
                                  className="swiper-image"
                                  src={data.img}
                                  alt=""
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </div>
                    )}
                  </Swiper>

                  <div className="mt-3">
                    <Swiper
                      modules={[Thumbs]}
                      onSwiper={setThumbsSwiper}
                      freeMode={true}
                      watchSlidesProgress={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 5,
                        },
                        480: {
                          slidesPerView: 5,
                        },
                        768: {
                          slidesPerView: 5,
                        },
                        1024: {
                          slidesPerView: 5,
                        },
                        1440: {
                          slidesPerView: 5,
                        },
                        2560: {
                          slidesPerView: 5,
                        },
                      }}
                    >
                      {brainImgType === "maths" && (
                        <div>
                          {mathsDetails.map((data, index) => (
                            <SwiperSlide key={index}>
                              <div
                                key={data.id}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <div>
                                  <img
                                    className="swiper-pagination-image"
                                    src={data.img}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </div>
                      )}
                      {brainImgType === "lang" && (
                        <div>
                          {langDetails.map((data) => (
                            <SwiperSlide>
                              <div
                                key={data.id}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <div>
                                  <img
                                    className="swiper-pagination-image"
                                    src={data.img}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </div>
                      )}
                      {brainImgType === "hand" && (
                        <div>
                          {handDetails.map((data) => (
                            <SwiperSlide>
                              <div
                                key={data.id}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <div>
                                  <img
                                    className="swiper-pagination-image"
                                    src={data.img}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </div>
                      )}
                      {brainImgType === "feet" && (
                        <div>
                          {feetDetails.map((data) => (
                            <SwiperSlide>
                              <div
                                key={data.id}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <div>
                                  <img
                                    className="swiper-pagination-image"
                                    src={data.img}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </div>
                      )}
                    </Swiper>
                  </div>
                </main>
              </div>
            </div>

            <div className="col-lg-4 tab-device-flex hide-for-mobile-device">
              <div className="d-flex justify-content-end">
                <div
                  onClick={() => setBrainImgType("lang")}
                  className={`brain-angle-view-div primary-dark-text ${
                    brainImgType === "lang" && "activated-brain-tab"
                  }`}
                >
                  <h2 className="brain-angle-title primary-dark-text reading-and-language">
                    Reading and Language <br /> Acquisition
                  </h2>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <div
                  onClick={() => setBrainImgType("feet")}
                  className={`brain-angle-view-div primary-dark-text ${
                    brainImgType === "feet" && "activated-brain-tab"
                  }`}
                >
                  <h2 className="brain-angle-title">
                    Motor Movement <br /> Feet
                  </h2>
                </div>
              </div>
            </div>

            {/* tabs for mobile device */}
            <div className="show-for-mobile-device">
              <div
                onClick={() => setBrainImgType("maths")}
                className={`brain-angle-view-div primary-dark-text ${
                  brainImgType === "maths" && "activated-brain-tab"
                }`}
              >
                <h2 className="brain-angle-title">
                  Mathematics and <br /> Logic
                </h2>
              </div>

              <div
                onClick={() => setBrainImgType("hand")}
                className={`brain-angle-view-div primary-dark-text ${
                  brainImgType === "hand" && "activated-brain-tab"
                }`}
              >
                <h2 className="brain-angle-title">
                  Motor Movement <br /> Hands
                </h2>
              </div>

              <div
                onClick={() => setBrainImgType("lang")}
                className={`brain-angle-view-div primary-dark-text ${
                  brainImgType === "lang" && "activated-brain-tab"
                }`}
              >
                <h2 className="brain-angle-title primary-dark-text reading-and-language">
                  Reading and Language <br /> Acquisition
                </h2>
              </div>

              <div
                onClick={() => setBrainImgType("feet")}
                className={`brain-angle-view-div primary-dark-text ${
                  brainImgType === "feet" && "activated-brain-tab"
                }`}
              >
                <h2 className="brain-angle-title">
                  Motor Movement <br /> Feet
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrainCarousel;
