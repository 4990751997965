import React, { useContext, useEffect, useState } from "react";
import "../LearnAnalytics/LearnAnalytic.css";
import "../../Blog/Blogs/Blogs.css";
import "./DynamicBlog.css";
import { Link, useParams } from "react-router-dom";
import facebook from "../../../assets/icon/facebook.png";
import twitter from "../../../assets/icon/twitter.png";
import linkedIn from "../../../assets/icon/linkedIn.png";
import copy from "../../../assets/icon/copy .png";
import userService from "../../../services/userServices";
import { LoadingContext } from "../../../App";

const DynamicBlog = () => {
  const { title } = useParams();
  const [blog, setBlog] = useState({});
  const { setLoading } = useContext(LoadingContext);
  const [open, setOpne] = useState(false);

  useEffect(() => {
    handleDynamicBlog(title.replace(/_/g, " "));
  }, [title]);

  const handleDynamicBlog = async (title) => {
    try {
      setLoading(true);

      const response = await userService.getDynamicBlog(title);

      if (response.error) {
        alert(response?.error?.message || "Something went worng!");
      } else {
        setBlog(response.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };
  console.log(blog);
  useEffect(() => {
    setTimeout(() => {
      setOpne(false);
    }, 1000);
  }, [open]);

  return (
    <>
      {/* Header */}
      <section
        className={`dynamic-blog-header-bg ${
          blog.blogTitle ===
            "BAAI: Unlocking the Secrets of User Engagement in the Attention Economy with AI" &&
          "BAAI-banner"
        }`}
        style={{
          backgroundImage: `linear-gradient(125.69deg, rgba(41, 41, 41, 0.74) 0%, rgba(25, 25, 25, 0.74) 100%), url(${blog.coverImg})`,
        }}
      ></section>
      <div>
        <div className="blog-hero-text-bg d-flex justify-content-center">
          <div
            className={`dynamic-blog-header-content analytics-header-content d-flex align-items-center ${
              blog.blogTitle ===
                "BAAI: Unlocking the Secrets of User Engagement in the Attention Economy with AI" &&
              "BAAI-banner-content"
            }`}
          >
            <div className="row">
              <div className="col-md-5 d-flex align-items-center">
                <img className="blog-header-image" src={blog.coverImg} alt="" />
              </div>
              <div className="col-md-7 d-flex align-items-center">
                <div>
                  <div className="d-flex date-div mt-1">
                    <p
                      style={{ color: "gray", width: "110px" }}
                      className="date"
                    >
                      {blog.date}
                    </p>{" "}
                    <strong style={{ color: "gray" }} className="popular-dot">
                      .
                    </strong>{" "}
                    <p style={{ color: "gray" }} className="date">
                      10 min read
                    </p>
                  </div>
                  <div>
                    {blog.tag && (
                      <>
                        {blog.tag.map((data, index) => (
                          <Link to={`/blogs/tag/${data.tags}`} key={index}>
                            <button className={`tag-btn-${index + 1}`}>
                              <small>{data.tags}</small>
                            </button>
                          </Link>
                        ))}
                      </>
                    )}
                  </div>
                  <div>
                    <h1 className="dynamic-blog-top-header-title">
                      {blog.blogTitle}
                    </h1>
                  </div>

                  <section>
                    <h6 className="share-via mt-3">Share Via:</h6>
                    <div className="d-flex flex-wrap mt-3">
                      <div
                        className="d-flex my-2 text-decoration-none pointer-cursor"
                        onClick={() =>
                          window.open(
                            `https://www.facebook.com/sharer.php?u=${window.location.href}`,
                            "Popup",
                            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
                          )
                        }
                      >
                        <div className="fb-share-text-bg">
                          <p className="facebook-text px-3">FaceBook</p>
                        </div>
                        <div className="facebook-share-icon">
                          <img src={facebook} alt="" />
                        </div>
                      </div>
                      <div
                        className="d-flex m-2 text-decoration-none pointer-cursor"
                        onClick={() =>
                          window.open(
                            `https://twitter.com/intent/tweet?url=${window.location.href}`,
                            "Popup",
                            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
                          )
                        }
                      >
                        <div className="twitter-share-text-bg">
                          <p className="facebook-text px-3">Twitter</p>
                        </div>
                        <div className="twitter-share-icon">
                          <img src={twitter} alt="" />
                        </div>
                      </div>
                      <div
                        className="d-flex my-2 text-decoration-none pointer-cursor"
                        onClick={() =>
                          window.open(
                            `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
                            "Popup",
                            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
                          )
                        }
                      >
                        <div className="linkedIn-share-text-bg">
                          <p className="facebook-text px-3">LinkedIn</p>
                        </div>
                        <div className="linkedIn-share-icon">
                          <img src={linkedIn} alt="" />
                        </div>
                      </div>

                      <div
                        className="d-flex m-2 more-share-div"
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                          setOpne(true);
                        }}
                      >
                        <div className="plus-share-text-bg">
                          <p className="copy-text px-3">Copy</p>
                        </div>
                        <div className="Plus-share-icon">
                          <img src={copy} alt="" />
                        </div>
                      </div>
                      <div className="custom-toltip">
                        {open && <p>Copied</p>}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-background primary-light-bg">
        <div className="">
          <div className="container">
            <div className="">
            
              <div className="main-blog-content mt-5">
                <div className="d-flex justify-content-center blog-spacing-right">
                  <div className="focci-sdk-container">
                    <div>
                      {blog.blogContent && (
                        <>
                          {blog.blogContent.map((data, index) => (
                            <div>
                              <img
                                className="img-fluid blog-big-img"
                                src={data.img}
                                alt=""
                              />
                              <h5 className="main-blog-color-heading primary-dark-text">
                                {data.heading}
                              </h5>
                              <p>
                                {data.description
                                  .split("\n")
                                  .map((data, index) => (
                                    <p className="main-blog-text secondary-dark-text">
                                      {data}
                                    </p>
                                  ))}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DynamicBlog;
