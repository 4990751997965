import React from "react";
import "./ScienceHeader.css";
import science1 from "../../../assets/science/science-1.png";
import science2 from "../../../assets/science/science-2.png";
import science3 from "../../../assets/science/science-3.png";

const ScienceHeader = () => {
  return (
    <section className="primary-light-bg science-top-header">
      <div className="container">
        <div className="science-banner">
          <h1 className="secondary-dark-text the-science-text">The Science</h1>
          <h1 className="secondary-dark-text behind-brainalive-text">
            <span style={{ color: "#6C6C75", fontWeight: 300 }}>Behind</span>{" "}
            Brainalive
          </h1>
          <div className="science-header-images">
            <img src={science1} alt="" />
            <img src={science2} alt="" />
            <img src={science3} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScienceHeader;
