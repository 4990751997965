import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import ScienceHeader from "../components/Science/ScienceHeader/ScienceHeader";
import ArtificialIntelligence from "../components/Science/ArtificialIntelligence/ArtificialIntelligence";
import BrainCarousel from "../components/Science/BrainCarousel/BrainCarousel";
import BrainWave from "../components/Science/BrainWave/BrainWave";
import Publications from "../components/Science/Publications/Publications";

const SciencePage = () => {
  useEffect(() => {
    document.title = "Science | BrainAlive";
  }, []);
  return (
    <>
      <Navbar />
      <ScienceHeader />
      <ArtificialIntelligence />
      <BrainCarousel />
      <BrainWave />
      <Publications />
      <Footer />
    </>
  );
};

export default SciencePage;
