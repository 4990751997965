import React, { useEffect } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "./AboutUs.css";
import vision from "../../assets/vision.png";
import visionMob from "../../assets/vision-mob.png";
import Mision from "../../assets/mission.png";
import MisionMob from "../../assets/mission-mob.png";
import AOS from "aos";
import "aos/dist/aos.css";
import abbas from "../../assets/team/abbas.png";
import sujit from "../../assets/team/sujit.png";
import anwesha from "../../assets/team/anwesha.png";
import anirban from "../../assets/team/anirban.png";
import saugat from "../../assets/team/saugat.png";
import artush from "../../assets/team/artus.png";
import gaurab from "../../assets/team/gaurav.png";
import rishav from "../../assets/team/rishav.png";
import nikhil from "../../assets/team/nikhil.png";
import sonal from "../../assets/team/sonal.png";
import sarthak from "../../assets/team/sarthak.png";
import nore from "../../assets/team/noore.png";
import tejas from "../../assets/team/tejas.png";
import shourya from "../../assets/team/shourya.png";
import aman from "../../assets/team/aman.png";
import aquib from "../../assets/team/aquib.png";
import reshule from "../../assets/team/reshul.png";
import deeksha from "../../assets/team/deeksha.png";
import naeem from "../../assets/team/naeem.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MediaCoverage from "../Home/MediaCoverage";
import { Link } from "react-router-dom";

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section>
      {/* <!-- <main> --> */}
      <div className="about-section">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-9 col-lg-12 col-xl-12 about-hero-text">
              <div className="text-center">
                <div
                  style={{ fontWeight: 300, fontFamily: "Nunito" }}
                  className="smallFocii"
                >
                  Who are we
                </div>
                <h1>
                  We’re BrainAlive.
                  <br />
                  We're The Wizards Behind The Tech Curtain, Conjuring Up AI
                  Magic For A Smarter Future!
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- </main>  --> */}

      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="vision me-sm-4">
                <div className="row">
                  <div className="col-sm-12 order-sm-last">
                    <picture>
                      <source media="(min-width:650px)" srcset={vision} />
                      <source media="(min-width:465px)" srcset={visionMob} />
                      <img src={visionMob} alt="focii" className="img-fluid" />
                    </picture>
                    {/* <!-- <img src="./img/vision.png" className="img-fluid" alt=""> --> */}
                  </div>
                  <div className="col-sm-12">
                    <div
                      style={{
                        borderTopRightRadius: "24px",
                        borderTopLeftRadius: "24px",
                      }}
                      className="vision-content"
                    >
                      <h3>Our Vision</h3>
                      <p>
                        BrainAlive redefines digital engagement by combining
                        neuroscience, computer vision, and NLP to decode the
                        complexities in human engagement. By leveraging
                        blockchain, we ensure secure, incentivized and anonymous
                        data sharing without compromising privacy. Our vision is
                        a harmonized digital world where technology augments
                        human connection ethically and safely.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="vision ms-sm-4">
                <div className="row">
                  <div className="col-sm-12">
                    <picture>
                      <source media="(min-width:650px)" srcset={Mision} />
                      <source media="(min-width:465px)" srcset={MisionMob} />
                      <img
                        srcset={MisionMob}
                        alt="focii"
                        className="img-fluid"
                      />
                    </picture>
                    {/* <!-- <img src="./img/mission.png" className="img-fluid" alt=""> --> */}
                  </div>
                  <div className="col-sm-12">
                    <div
                      style={{
                        borderBottomRightRadius: "24px",
                        borderBottomLeftRadius: "24px",
                      }}
                      className="vision-content"
                    >
                      <h3>Our Mission</h3>
                      <p>
                        At BrainAlive, our mission is to empower human potential
                        through a profound understanding of user states, driven
                        by the fusion of vision and brain science. We aspire to
                        pioneer a user experience that transcends the ordinary,
                        delving into the intricate layers of cognition to truly
                        champion the essence of each individual.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MediaCoverage />
      {/* Team member */}
      <div className="teamMember">
        <div className="container">
          <h2>Meet The BrainAlive Team</h2>
          <p>Meet the Visionaries Behind</p>
          <div className="team">
            <h3>The Founders</h3>
            <div className="team-grid">
              <a
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
                href="https://www.linkedin.com/in/mohammad-abbas-mehdi-a6493286/"
                target="_blank"
              >
                <div className="team-image">
                  <img src={abbas} alt="" />
                </div>
                <div className="team-name">Abbas Mehdi</div>
                <div className="designation">Co-Founder | Embedded Tech</div>
              </a>

              <a
                href="https://www.linkedin.com/in/sujit-roy01/"
                target="_blank"
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={sujit} alt="" />
                </div>
                <div className="team-name">Dr. Sujit Roy</div>
                <div className="designation">
                  Founding Advisor | Technology and Operations
                </div>
              </a>
            </div>
          </div>
          <div className="team">
            <h3>The Advisory Board</h3>
            <div className="team-grid">
              <a
                href="https://scholar.google.co.in/citations?user=HuGUohQAAAAJ&hl=en"
                target="_blank"
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={anwesha} alt="" />
                </div>
                <div className="team-name">Dr. Anwesha Khasnobish</div>
                <div className="designation">BCI Technology</div>
              </a>

              <a
                href="https://www.essex.ac.uk/people/chowd32703/anirban-chowdhury"
                target="_blank"
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={anirban} alt="" />
                </div>
                <div className="team-name">Dr. Anirban Chowdhury</div>
                <div className="designation">Neural Engineering</div>
              </a>

              <a
                href="https://saugatbh.github.io/saugat/"
                target="_blank"
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={saugat} alt="" />
                </div>
                <div className="team-name">Dr. Saugat Bhattacharyya</div>
                <div className="designation">Brain Computer Interfacing</div>
              </a>

              <a
                href="https://www.linkedin.com/in/artus-krohn-grimberghe/"
                target="_blank"
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={artush} alt="" />
                </div>
                <div className="team-name">Artus Krohn-Grimberghe</div>
                <div className="designation">AI Enterprise Solutions</div>
              </a>
            </div>
          </div>

          <div className="team">
            <h3>The Team</h3>
            <div className="team-grid">
              {/* <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={gaurab} alt="" />
                </div>
                <div className="team-name">Dr. Gaurav Garg</div>
                <div className="designation">Machine Learning</div>
              </div>

              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={rishav} alt="" />
                </div>
                <div className="team-name">Rishav Verma</div>
                <div className="designation">Product Management</div>
              </div>
              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={nikhil} alt="" />
                </div>
                <div className="team-name">Dr. Nikhil Prabhu</div>
                <div className="designation">Research & Development</div>
              </div>
              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={sonal} alt="" />
                </div>
                <div className="team-name">Dr. Sonal Dixit</div>
                <div className="designation">Machine Learning</div>
              </div>
              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={sarthak} alt="" />
                </div>
                <div className="team-name">Sarthak Malik</div>
                <div className="designation">Embedded Firmware</div>
              </div>
              {/* <!-- </div> -->

              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={nore} alt="" />
                </div>
                <div className="team-name">Noore Mujjasam</div>
                <div className="designation">Hardware Engineering</div>
              </div>
              {/* <!-- </div><div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={tejas} alt="" />
                </div>
                <div className="team-name">Tejas Singh</div>
                <div className="designation">Product Management</div>
              </div>
              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={shourya} alt="" />
                </div>
                <div className="team-name">Shourya Pratap Bhadauriya</div>
                <div className="designation">Software Development</div>
              </div>
              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={aman} alt="" />
                </div>
                <div className="team-name">Aman Tyagi</div>
                <div className="designation">Design</div>
              </div>
              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={aquib} alt="" />
                </div>
                <div className="team-name">Aquib Ahmed</div>
                <div className="designation">Software Development</div>
              </div>
              {/* <!-- </div> -->
              <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={reshule} alt="" />
                </div>
                <div className="team-name">Reshul Wate</div>
                <div className="designation">Software Development</div>
              </div>
              {/* <!-- </div> -->
               <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img className="rounded" src={naeem} alt="" />
                </div>
                <div className="team-name">Naeem Miah</div>
                <div className="designation">Software Development</div>
              </div>
              {/* <!-- <div className="col-sm-4"> --> */}
              <div
                className="cards aos-init aos-animate text-decoration-none pe-auto"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="team-image">
                  <img src={deeksha} alt="" />
                </div>
                <div className="team-name">Deeksha Kashyap</div>
                <div className="designation">Quality Assurance</div>
              </div>
              {/* <!-- </div> --> */}
            </div>
          </div>
        </div>
      </div>
      <div className="red-section">
        <div className="container">
          <div className="reasoly-box">
            <h2>Wanna join the team?</h2>
            {/* <a href="#" className="btn btn-dark"> Get in touch!</a> */}
            <Link to="/contact" className="btn btn-dark">
              {" "}
              Get in touch!
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
