import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import OnlineLearnings from "../../../components/Blogs/OnlineLearning/OnlineLearning";
import Footer from "../../../components/Footer/Footer";

const OnlineLearning = () => {
  useEffect(() => {
    document.title =
      "Can online learning be made a fitting alternative to traditional classroom? | Braina Live Blog";
  }, []);
  return (
    <div>
      <Navbar />
      <OnlineLearnings />
      <Footer />
    </div>
  );
};

export default OnlineLearning;
