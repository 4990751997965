import React, { useState } from "react";
import "../../css/style.css";
import "./Navbar.css";
import "../../css/responsive.css";
import Logo from "../../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import topArrow from "../../assets/icon/top-arrow.png";
import bottomArrow from "../../assets/icon/bottom-arrow.png";
import ClickOutSide from "./ClickOutSide";

const Navbar = () => {
  const [openDropDown, setOpenDropdown] = useState(false);

  return (
    <nav className="navbar navbar-expand-md navbar-light fixed-top">
      <div className="container">
        <Link to="/home" className="navbar-brand">
          {" "}
          <img src={Logo} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mx-auto mb-2 mb-md-0">
            <li className="nav-item">
              <Link
                to="/home"
                as={NavLink}
                className={`nav-link ${
                  window.location.pathname === "/home" ? "active" : ""
                }`}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about"
                as={NavLink}
                className={`nav-link ${
                  window.location.pathname === "/about" ? "active" : ""
                }`}
              >
                About us
              </Link>
            </li>
            {/* dropdown */}

            <li className="nav-item">
              <ClickOutSide setOpenDropdown={setOpenDropdown}>
                <button
                  className="first-dropdown"
                  onClick={() =>
                    setOpenDropdown((openDropDown) => !openDropDown)
                  }
                >
                  Product{" "}
                  {openDropDown ? (
                    <img src={topArrow} alt="" />
                  ) : (
                    <img src={bottomArrow} alt="" />
                  )}
                </button>
                {openDropDown && (
                  <div className="products-dropdown">
                    <div className="products-dropdown-sections">
                      <div className="product-division">
                        <h5 className="division-heading">Computer Vision</h5>
                        {/* <div
                          className={`products-item ${
                            window.location.pathname === "/focii"
                              ? "active-product"
                              : ""
                          }`}
                        > */}
                        {/* <Link to="/focii" className="text-decoration-none">
                            <h6>FOCII</h6>
                            <p>Grow your audience with better content</p>
                          </Link> */}

                        <div className="brain-computer-interface-sections">
                          <div
                            className={`products-item ${
                              window.location.pathname === "/focii"
                                ? "active-product"
                                : ""
                            }`}
                          >
                            <Link to="/focii" className="text-decoration-none">
                              <h6>FOCII Desktop</h6>
                              <p>Grow your audience with better content</p>
                            </Link>
                          </div>
                          <div
                            className={`products-item ${
                              window.location.pathname === "/focii_youtube"
                                ? "active-product"
                                : ""
                            }`}
                          >
                            <Link
                              to="/focii_youtube"
                              className="text-decoration-none"
                            >
                              <h6>FOCII for YouTube</h6>
                              <p>Earn Rewards while watching YouTube videos</p>
                            </Link>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>

                      <div className="brain-computer-interface">
                        <h5 className="division-heading">
                          Brain Computer Interface
                        </h5>

                        <div className="brain-computer-interface-sections">
                          <div
                            className={`products-item ${
                              window.location.pathname === "/bci"
                                ? "active-product"
                                : ""
                            }`}
                          >
                            <Link to="/bci" className="text-decoration-none">
                              <h6>Headsets</h6>
                              <p>Multi-channel EEG & fNIRS Headsets</p>
                            </Link>
                          </div>
                          <div
                            className={`products-item ${
                              window.location.pathname === "/abha"
                                ? "active-product"
                                : ""
                            }`}
                          >
                            <Link to="/abha" className="text-decoration-none">
                              <h6>ABHA</h6>
                              <p>Relearn Your Forgotten Mobility</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ClickOutSide>
            </li>

            <li className="nav-item">
              <Link
                to="/science"
                className={`nav-link ${
                  window.location.pathname === "/science" ? "active" : ""
                }`}
                as={NavLink}
              >
                {" "}
                Science
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="https://www.baai.co/"
                target="_blank"
                className={`nav-link`}
                as={NavLink}
              >
                {" "}
                Rewards
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/blog"
                className={`nav-link ${
                  window.location.pathname === "/blog" ? "active" : ""
                }`}
                as={NavLink}
              >
                {" "}
                Blog
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="/contact"
                className={`nav-link ${
                  window.location.pathname === "/contact" ? "active" : ""
                }`}
                as={NavLink}
              >
                {" "}
                Contact
              </Link>
            </li> */}
          </ul>
          <form className="d-flex">
            <button className="btn btn-dark nav-book-demo-btn">
              <Link to="/contact"> Book a Demo</Link>
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
