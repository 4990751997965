import React from "react";
import './ReadyBanner.css'
import frame1 from './Frame8905.svg'


const EarnBanner = () =>{

    const handleImageClick = () => {
        // Open the external link in a new tab
        window.open('https://chromewebstore.google.com/detail/focii-youtube/jamlalnkhijfjgbohdakdfgdnidoghca', '_blank');
      };

    return(<div className='readyBanner-container'>

    <div className="readyBanner">
            <img src={frame1} alt="" onClick={handleImageClick} className="earnBannerClick"/>
    </div>

</div>)
}

export default EarnBanner;