import React, { useEffect, useRef } from "react";

const ClickOutSide = ({
  children,
  exceptionRef,
  setOpenDropdown,
  className,
}) => {
  const wrapperRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);

    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  }, []);

  const handleClickListener = (event) => {
    let clickedInside;
    if (exceptionRef) {
      clickedInside =
        (wrapperRef && wrapperRef.current.contains(event.target)) ||
        exceptionRef.current === event.target ||
        exceptionRef.current.contains(event.target);
    } else {
      clickedInside = wrapperRef && wrapperRef.current.contains(event.target);
    }

    if (clickedInside) return;
    else setOpenDropdown(false);
  };
  return (
    <div ref={wrapperRef} >
      {children}
    </div>
  );
};

export default ClickOutSide;
